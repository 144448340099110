<template>
  <div>
    <en-table-layout :toolbar-two="true" :table-data="tableData.data" @selection-change="handleSelectionChange"
      ref="enTableLayout">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="储值卡" name="3"></el-tab-pane>
          <el-tab-pane label="AI智能套餐卡" name="5"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item v-if="activeName == '3'" label="类型">
          <el-select v-model="searchForm.app_type_shop_type_flag" style="width: 110px" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option :value="1" label="现金储值卡"></el-option>
            <el-option v-if="double_choice_mall_open" :value="2" label="双选储值卡"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName == '5'" label="类型">
          <el-select v-model="searchForm.app_type_shop_type_flag" style="width: 110px" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option :value="3" label="智能套餐卡"></el-option>
            <el-option v-if="double_choice_mall_open" :value="4" label="计次卡"></el-option>
            <el-option v-if="double_choice_mall_open" :value="5" label="宅配卡"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号状态">
          <el-select v-model="searchForm.status" style="width: 110px" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option value="0" label="未激活"></el-option>
            <el-option value="1" label="已激活"></el-option>
            <el-option value="2" label="已禁用"></el-option>
            <el-option value="3" label="已绑定"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" class="col-auto" ref="cardRange" :error="cardRangeError">
          <el-input style="width: 100px" v-model.trim="searchForm.card_code_start" clearable
            @blur="handleCardRange()" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 90px" v-model.trim="searchForm.card_code_end" clearable @blur="handleCardRange()" />
        </el-form-item>
        <el-form-item label="操作日期" class="col-auto">
          <el-date-picker style="width: 210px" v-model="order_time_range" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="客户名称">
          <el-input style="width: 150px" v-model.trim="searchForm.company" placeholder="请输入客户名称" clearable />
        </el-form-item>
      </template>
      <template slot="toolbar-two">
        <el-form-item class="col-auto">
          <el-input style="width: 360px" v-model.trim="searchForm.keywords" placeholder="请输入关键词" clearable>
            <el-select style="width: 110px" v-model="search_type" slot="prepend">
              <el-option label="卡券名称" value="card_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
              <el-option label="礼包名称" v-if="activeName == '5'" value="exchange_items"></el-option>
              <!-- <el-option label="客户名称" value="company"></el-option> -->
              <el-option label="销售人员名称" value="active_salesman"></el-option>
              <el-option label="关联销售单号" value="sell_trade_sn"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="saleCardRefreshPage('search')">搜索</el-button>
          <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 0)">导出全部</el-button>
          <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 1)">导出勾选项</el-button>
        </el-form-item>
      </template>

      <!-- 储值卡 -->
      <template slot="table-columns" v-if="activeName == '3'">
        <el-table-column align="center" type="selection" width="55" fixed />
        <el-table-column align="center" label="编号" width="60" fixed>
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡券名称" width="220" show-overflow-tooltip prop="card_name" fixed />
        <el-table-column align="center" label="所属商城" prop="shop_name" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="卡号" width="120" prop="card_code" show-overflow-tooltip />
        <el-table-column align="center" label="类型" width="110">
          <template slot-scope="scope">
            {{ getAppTypeName(scope.row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡号状态" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.statuses === 1">已激活</div>
            <div v-if="scope.row.statuses === 2">未激活</div>
            <div v-if="scope.row.statuses === 3">已绑定</div>
            <div v-if="scope.row.statuses === 4">已禁用<br />（企业关卡）</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="绑定手机号" prop="binding_member_mobile" width="150"></el-table-column>
        <el-table-column align="center" label="操作日期" width="150">
          <template slot-scope="scope">
            {{ scope.row.update_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="面值" prop="card_value" width="100" />
        <el-table-column prop="left_value" align="center" label="余额" width="100" />
        <el-table-column align="center" label="客户名称" prop="client_name" show-overflow-tooltip />
        <el-table-column align="center" label="销售人员" show-overflow-tooltip prop="active_salesman" width="120" />
        <el-table-column align="center" label="关联销售单号" prop="sell_trade_sn" width="180" fixed="right" />
      </template>
      <!-- 储值卡 -->

      <!-- AI智能套餐卡 -->
      <template slot="table-columns" v-if="activeName == '5'">
        <el-table-column align="center" type="selection" width="55" fixed />
        <el-table-column align="center" label="编号" width="60" fixed>
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡券名称" width="220" show-overflow-tooltip prop="card_name" fixed />
        <el-table-column align="center" label="所属商城" prop="shop_name" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="卡号" width="120" prop="card_code" show-overflow-tooltip />
        <el-table-column align="center" label="类型" width="110">
          <template slot-scope="scope">
            {{ getAppTypeName(scope.row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="card_value" label="价位" width="110"></el-table-column>
        <el-table-column align="center" label="卡号状态" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.statuses === 1">已激活</div>
            <div v-if="scope.row.statuses === 2">未激活</div>
            <div v-if="scope.row.statuses === 3">已绑定</div>
            <div v-if="scope.row.statuses === 4">已禁用<br />（企业关卡）</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="exchange_items" label="兑换礼包" width="150"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="兑换时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.exchange_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="客户名称" prop="client_name" show-overflow-tooltip />
        <el-table-column align="center" label="销售人员" show-overflow-tooltip prop="active_salesman" width="120" />
        <el-table-column align="center" label="关联销售单号" prop="sell_trade_sn" width="180" fixed="right" />
      </template>
      <!-- AI智能套餐卡 -->

      <el-pagination slot="pagination" @size-change="saleCardRefreshPage('handlePageSizeChange', $event)"
        @current-change="saleCardRefreshPage('handlePageCurrentChange', $event)" :current-page="tableData.page_no"
        :page-size="tableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total" />
    </en-table-layout>
  </div>
</template>

<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import * as API_Sale from "@/api/saleCard";
import { Foundation } from "../../../../ui-utils";
import { handleDownload } from "@/utils";
import { createHeaders } from "./utils/create";
import * as API_SaleOrder from "@/api/saleCard";
import * as API_order from "@/api/order";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import {
  changeDataList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";

export default {
  name: "cardNoSearch",
  mixins: [mixins],
  components: {
    EnTableLayout,
  },
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      activeName: '3',
      search_type: "card_name",
      order_time_range: "",
      params: {
        page_no: 1,
        page_size: 20,
        app_type: "3"
      },
      searchForm: {
        card_code_start: "",
        card_code_end: "",
        status: "",
        keywords: "",
        company: "",
        // shop_type: ""
        app_type_shop_type_flag: ""
      },
      tableData: {},
      cardRangeError: "",
      multipleSelection: [],
    };
  },
  mounted () {
    API_order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.getList();
  },
  watch: {
    "searchForm.card_code_start" (newVal, oldVal) {
      if (!newVal && !this.searchForm.card_code_end) {
        this.cardRangeError = "";
      }
    },
    "searchForm.card_code_end" (newVal, oldVal) {
      if (!newVal && !this.searchForm.card_code_start) {
        this.cardRangeError = "";
      }
    },
  },
  methods: {
    handleClick (e) {
      this.search_type = "card_name";
      this.params = {
        page_no: 1,
        page_size: 20,
        app_type: Number(this.activeName)
      };
      this.searchForm = {
        card_code_start: "",
        card_code_end: "",
        status: "",
        keywords: "",
        company: "",
        // shop_type: ""
        app_type_shop_type_flag: ""
      };
      this.tableData.data = [];
      this.$refs.enTableLayout.clearSelected();
      this.getList();

    },
    // 卡号区间校验
    handleCardRange () {
      /** 判断输入的卡号是否包含前缀 **/
      const cardReg = /^[a-zA-Z]+\d{3,}$|^\d{3,}$/;
      const letterReg = /^[a-zA-Z]+\d+$/;
      if (this.searchForm.card_code_start && this.searchForm.card_code_end) {
        if (
          cardReg.test(this.searchForm.card_code_start) &&
          cardReg.test(this.searchForm.card_code_end)
        ) {
          // 包含前缀
          if (
            letterReg.test(this.searchForm.card_code_start) &&
            letterReg.test(this.searchForm.card_code_end)
          ) {
            const startPrefix = /[a-zA-Z]+/.exec(
              this.searchForm.card_code_start
            )[0];
            const endPrefix = /[a-zA-Z]+/.exec(
              this.searchForm.card_code_end
            )[0];
            if (startPrefix !== endPrefix) {
              this.cardRangeError = "当前卡号区间格式不一致。";
            } else if (startPrefix.length > 5 || endPrefix.length > 5) {
              console.log("卡号前缀大于五位数");
              this.cardRangeError =
                "当前卡号区间格式不正确，请重新填写卡号区间。";
            } else {
              // 判断数组部分大于3位是否以0开头
              const startNum = /\d+/.exec(this.searchForm.card_code_start)[0];
              const endNum = /\d+/.exec(this.searchForm.card_code_end)[0];
              console.log(
                startNum,
                endNum,
                /\d+/.exec(this.searchForm.card_code_start)
              );
              this.cardRangeError = "";
              // if (/^0\d{3,}/.test(startNum) || /^0\d{3,}/.test(endNum)) {
              //   console.log('大于3位以0开头')
              //   this.cardRangeError = '当前卡号区间格式不正确，请重新填写卡号区间。'
              // } else {
              //   this.cardRangeError = ''
              // }
            }
          } else if (
            (letterReg.test(this.searchForm.card_code_start) &&
              !letterReg.test(this.searchForm.card_code_end)) ||
            (!letterReg.test(this.searchForm.card_code_start) &&
              letterReg.test(this.searchForm.card_code_end))
          ) {
            this.cardRangeError = "当前卡号区间格式不一致。";
          } else {
            if (
              parseInt(this.searchForm.card_code_end) <
              parseInt(this.searchForm.card_code_start)
            ) {
              this.cardRangeError =
                "结束框卡号数字部分不能小于起始输入框卡号数字部分。";
            } else if (
              /^0\d{3,}/.test(this.searchForm.card_code_end) ||
              /^0\d{3,}/.test(this.searchForm.card_code_start)
            ) {
              // 满足3位以上数字且以0开头
              // console.log("小于3位以0开头");
              // this.cardRangeError =
              //   "当前卡号区间格式不正确，请重新填写卡号区间。";
              this.cardRangeError = ""
            } else {
              this.cardRangeError = "";
            }
          }
        } else {
          console.log("不包含前缀");
          this.cardRangeError = "当前卡号区间格式不正确，请重新填写卡号区间。";
        }
      } else if (
        !this.searchForm.card_code_start &&
        !this.searchForm.card_code_end
      ) {
        this.cardRangeError = "";
      } else {
        console.log("不包含前缀");
        this.cardRangeError = "请输入正确卡号区间进行搜索";
      }
    },
    search () {
      this.params.page_no = 1;
      this.params.company = this.searchForm.company;
      if (this.cardRangeError) {
        this.$message.error("请先输入正确的卡号区间再搜索");
        return;
      }
      if (
        this.searchForm.card_code_start &&
        this.searchForm.card_code_end &&
        (this.searchForm.status ||
          this.searchForm.keywords ||
          this.searchForm.company ||
          this.order_time_range)
      ) {
        this.$message.error("卡号区间搜索不能和其他筛选条件同时使用");
        return;
      }
      this.params["card_code_start"] = this.searchForm.card_code_start;
      this.params["card_code_end"] = this.searchForm.card_code_end;
      switch (this.searchForm.status) {
        case "0":
          this.params["active_status"] = 0;
          this.params["binding_status"] = 0;
          break;
        case "1":
          this.params["active_status"] = 1;
          this.params["binding_status"] = 0;
          break;
        case "2":
          this.params["active_status"] = 0;
          this.params["binding_status"] = 1;
          break;
        case "3":
          this.params["active_status"] = 1;
          this.params["binding_status"] = 1;
          break;
        case "":
          delete this.params["active_status"];
          delete this.params["status"];
          delete this.params["binding_status"];
      }
      delete this.params["startTime"];
      delete this.params["endTime"];
      if (this.order_time_range) {
        this.params["startTime"] = this.order_time_range[0].getTime() / 1000;
        this.params["endTime"] =
          this.order_time_range[1].getTime() / 1000 + 86399;
      }
      const whiteList = ['card_name', 'shop_name', 'exchange_items', 'active_salesman', 'sell_trade_sn'];
      whiteList.forEach(key => this.params[key] = undefined)
      if (this.searchForm.keywords) {
        this.params[this.search_type] = this.searchForm.keywords;
      } else {
        delete this.params[this.search_type];
      }
      this.getList();
    },
    getList () {
      const params = {
        ...this.params
      }
      if (this.searchForm.app_type_shop_type_flag) {
        delete params.app_type;
        params.app_type_shop_type_flag = this.searchForm.app_type_shop_type_flag;
      }
      API_SaleOrder.getCardNumList({
        ...params
      }).then((res) => {
        this.tableData = res;
        this.$nextTick(() => {
          this.$refs.enTableLayout.$refs.table.doLayout()
        })
      });
    },
    handlePageSizeChange (size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.getList();
    },
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getList();
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    exportCardList (type) {
      // 0 导出全部 1 导出选中
      let params = {
        page_no: 1,
        ...this.params,
        app_type: Number(this.activeName)
      };
      if (this.searchForm.app_type_shop_type_flag) {
        delete params.app_type;
        params.app_type_shop_type_flag = this.searchForm.app_type_shop_type_flag;
      }
      params.page_size = 999999;
      let index = this.activeName == '3' ? 0 : 1;
      let fileName = ["储值卡", "AI智能套餐卡"][index] + "列表";
      let dataHeaders = createHeaders();
      const filterVals = dataHeaders[index].vals;
      const tHeaders = dataHeaders[index].headers;
      if (type === 1) {
        if (!this.multipleSelection.length) {
          this.$message.error("请先选择要导出的数据");
          return false;
        }
        params["ids"] = this.multipleSelection.map((item) => {
          return item.id;
        });
      }
      if (this.searchForm.app_type_shop_type_flag) {
        delete params.app_type;
        params.app_type_shop_type_flag = this.searchForm.app_type_shop_type_flag;
      }
      API_Sale.getCardNumList(params).then((res) => {
        let data = res.data.map((item) => {
          item.statuses = [
            "",
            "已激活",
            "未激活",
            "已绑定",
            "已禁用（企业关卡）",
          ][item.statuses];
          item.update_time = Foundation.unixToDate(item.update_time);
          item.exchange_time = Foundation.unixToDate(item.exchange_time);
          item.app_type_shop_type_flag = this.getAppTypeName(item.app_type_shop_type_flag);
          return item;
        });
        handleDownload(data, tHeaders, filterVals, fileName);
        this.$refs.enTableLayout.clearSelected();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.col-auto {
  /deep/ .el-form-item__error {
    z-index: 8;
  }
}
</style>
